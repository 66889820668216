import React from "react";
import { Link } from "gatsby";
import { fluidRange } from "polished";
import styled from "@emotion/styled";

import Logo from "../Logo";
import { Box } from "@rebass/grid/emotion";
import Icon from "react-icons-kit";
import { phone } from "react-icons-kit/fa/phone";
import { bars } from "react-icons-kit/fa/bars";
import { times } from "react-icons-kit/fa/times";
import { stack as Menu } from "react-burger-menu";
import { useState } from "react";

const menu = [
  { name: "Immobilie verkaufen", link: "/immobilie-in-rudolstadt-verkaufen/" },
  { name: "Immobilienbewertung", link: "/immobilienbewertung-rudolstadt/" },
  { name: "Referenzen", link: "/referenzen/" },
  { name: "Angebote", link: "/immobilien-rudolstadt/" },
  { name: "Kontakt", link: "/kontakt/" },
];

const Tile = styled.div`
  padding: 15px;
  position: absolute;
  z-index: 20;
  width: 100%;
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20px,
    black calc(100% - 20px),
    transparent
  );
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20px,
    black calc(100% - 20px),
    transparent
  );
  @media (min-width: 1001px) {
    display: none;
  }
`;

const Nav = styled(Box)`
  display: flex;
  padding: 5px 20px;
  width: 100%;
  max-width: 1100px;
  align-items: center;
  justify-content: space-between;
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemInner = styled.span`
  position: relative;
  color: #ffffff;
  user-select: none;
  letter-spacing: 1px;

  &:before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ffffff;
    opacity: 0.6;
    transform: scale3d(0, 1, 1);
    transform-origin: center left;
    transition: 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
`;

const ItemLink = styled(Link)`
  display: block;
  color: white;
  text-decoration: none;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  ${fluidRange(
    {
      prop: "padding",
      fromSize: "10px",
      toSize: "15px",
    },
    "400px",
    "800px"
  )}

  &:hover,
  &:focus {
    ${ItemInner}:before {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  &.active {
    ${ItemInner} {
      :before {
        transform: scale3d(1, 1, 1);
      }
    }
  }
`;

const MobileLogo = styled.div`
  svg {
    display: table;
    width: 150px;
    margin: 0 auto;
  }
`;

const LogoItem = styled.div`
  @media (max-width: 999px) {
    display: none;
  }
  position: relative;
  padding: 0 10px;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  background-color: #96145266;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
`;

const PhoneLink = styled.a`
  margin-left: 8px;
  color: white;
  font-weight: bold;
`;

const LogoLink = (_) => (
  <Link
    style={{ display: "table", margin: "0 auto" }}
    activeClassName="active"
    to="/"
    children={<Logo white />}
  />
);

const BurgerIcon = styled.div`
  color: white;
  cursor: pointer;
`;

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Menu
        isOpen={menuOpen}
        onStateChange={(state) => setMenuOpen(state.isOpen)}
        disableAutoFocus
        width={"100%"}
        customCrossIcon={
          <Box color="white">
            <Icon icon={times} size={24} />
          </Box>
        }
        styles={{
          bmBurgerButton: {
            display: "none",
          },
          bmCrossButton: {
            width: 40,
            height: 40,
            top: 20,
            right: 10,
          },
          bmOverlay: {
            background: "#00000088",
            backdropFilter: "blur(4px)",
            WebkitBackdropFilter: "blur(4px)",
          },
          bmItemList: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          },
        }}
      >
        {menu.map((item, i) => (
          <ItemLink
            key={i}
            activeClassName="active"
            to={item.link}
            children={<ItemInner>{item.name}</ItemInner>}
          />
        ))}
      </Menu>
      <Tile>
        <TopBar>
          <small>Kostenlos beraten lassen:</small>
          <small>
            <PhoneLink href="tel:036450449933">
              <Icon icon={phone} />
              036450 / 44 99 33
            </PhoneLink>
          </small>
        </TopBar>
        <MobileNavContainer>
          <MobileLogo>
            <LogoLink />
          </MobileLogo>
          <BurgerIcon onClick={() => setMenuOpen(true)}>
            <Icon icon={bars} size={24} />
          </BurgerIcon>
        </MobileNavContainer>
        <NavContainer>
          <Nav>
            <LogoItem>
              <LogoLink />
            </LogoItem>
            <NavItems>
              {menu.map((item, i) => (
                <ItemLink
                  key={i}
                  activeClassName="active"
                  to={item.link}
                  children={<ItemInner>{item.name}</ItemInner>}
                />
              ))}
            </NavItems>
          </Nav>
        </NavContainer>
      </Tile>
    </>
  );
};

export default Navigation;
