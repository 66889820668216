import React from "react";
import { Helmet } from "react-helmet";

import "typeface-pt-serif";
import "typeface-open-sans";

import Footer from "../Footer";
import Navigation from "../Navigation";
import CompanySchema from "../CompanySchema";
import Whatsapp from "../Whatsapp";

const Page = ({ children, header, fixedHeader, address }) => (
  <div>
    <Helmet
      defaultTitle="Kranich Immobilien"
      titleTemplate="%s | Kranich Immobilien"
      meta={[
        {
          name: "keywords",
          content:
            "Immobilien, Haus, Verkauf, Suche, Thüringen, Kranichfeld, Weimar, Saalfeld, Rudolstadt",
        },
        { name: "msapplication-TileColor", content: "#961452" },
        { name: "msapplication-TileImage", content: "/mstile.png" },
        {
          name: "google-site-verification",
          content: "x92AkyGx3N-lL5DAPoehM9cpEaJW5TT4Fc1ztmNEF_s",
        },
      ]}
      link={[
        { rel: "shortcut icon", href: "/favicon.ico" },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png",
        },
      ]}
    >
      <script src="https://cmp.osano.com/16BZAFRu9t3oE15Bz/7af44277-59b9-480f-abb3-322083926543/osano.js"></script>
    </Helmet>
    <CompanySchema address={address} />
    <Navigation fixed={fixedHeader} />
    {header}
    <main>{children}</main>
    <Footer />
    <Whatsapp />
  </div>
);

export default Page;
