import React from "react";
import styled from "@emotion/styled";
import GatsbyLink from "gatsby-link";

import Logo from "../Logo";
import Address from "../Address";

const Container = styled.footer`
  padding: 15px;
  font-size: 12px;
  color: white;
  background-color: #662382;
  text-align: center;
  address {
    font-style: normal;
  }
  a {
    color: inherit;
    &:hover {
      color: white;
    }
  }
`;

const Tile = styled.div`
  padding: 70px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
`;

const Link = styled(GatsbyLink)`
  margin: 0 10px;
`;

const FooterTitle = styled.h2``;

const Attribution = styled.div`
  margin-top: 20px;
`;

const Footer = () => (
  <Container>
    <Tile>
      <Logo simple white />
      <FooterTitle>Kranich Immobilien</FooterTitle>
      <br />
      <Address />
      <nav>
        <Link to="/kontakt/">Kontakt</Link> |{" "}
        <Link to="/impressum/">Impressum</Link> |{" "}
        <Link to="/datenschutz/">Datenschutz</Link>
      </nav>
      <Attribution>
        Website powered by{" "}
        <a
          href="https://estatesync.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          EstateSync
        </a>{" "}
        and{" "}
        <a
          href="https://www.spatz.digital/"
          target="_blank"
          rel="noopener noreferrer"
        >
          spatz.digital
        </a>
      </Attribution>
    </Tile>
  </Container>
);

export default Footer;
